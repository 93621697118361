import featherApp from "../../feathersjs/feathersjs";
import {withErrorHandler} from "./common";
import {removeNullValue} from "./student";
import _ from "lodash";
import {fetchAllProfiles, sortMapping} from "./profile";

const EventService = featherApp.service("events");
const EventAttendanceService = featherApp.service("event-attendance");
const StatisticsEvents = featherApp.service("statistics-events");
const EventReview = featherApp.service("event-review");
const ReviewInteract = featherApp.service("event-review-interact");

export const fetchEvents = withErrorHandler(async (searchData) => {
  return await EventService.find(searchData);

})

export const fetchAllEvents = withErrorHandler(({params, sort, filter}) => {
    removeNullValue(params)
    removeNullValue(filter)
    const $sort = _.transform(sort, function (result, value, key) {
      result[key] = sortMapping[value]
      return result
    }, { createdAt: -1 });
    const {current = 1, pageSize: $limit = 1000, ...requestParams} = params
    const searchData = {query: {$sort, $limit, $skip: (current - 1) * $limit, ...filter,}}

    return fetchEvents(searchData);
  }
);

export const fetchEventByType = withErrorHandler((params) => {
    const searchData = {query: { $limit: 1000, type:params}}
    return fetchEvents(searchData);
  }
);

export const fetchCurrentEvents = async () => {
  const today = new Date();
  return fetchEvents({
    query: {
      from: {
        $lte: today
      },
      to: {
        $gte: today
      }
    }
  });
}
// fetchFutureEvents
export const fetchFutureEvents = () => {
  return fetchEvents({
    query: {
      from: {
        $gte: new Date()
      }
    }
  });
}
// fetchPastEvents
export const fetchPastEvents = () => {
  return fetchEvents({
    query: {
      to: {
        $lte: new Date()
      },
      $sort: {id: -1}
    }
  });
}

export const fetchEventReviews = withErrorHandler(async (params) => {
  const res = await EventReview.find(params);
  return res.data;
})

export const createReview = withErrorHandler(async (review) => {
  return EventReview.create(review);
})

export const updateReviewEvent = withErrorHandler(async (id, value) => {
  return await EventReview.patch(id, value);
})


export const fetchReviewByProfileId = withErrorHandler(async (profileId) => {
  return await fetchEventReviews({
    query: {
      profileId: profileId,
    }
  });
})
export const fetchReviewByEvent = withErrorHandler(async (eventId, star) => {
  return await fetchEventReviews({
    query: {
      eventId: eventId,
      ...(star && {rating: star})
    }
  });
})

//generate create event action
export const createEvent = withErrorHandler(async (event) => {
  return EventService.create(event);
})

//generate update event action
export const updateEvent = withErrorHandler(async (id, event) => {
  return await EventService.patch(id, event);
})
//generate delete event action
export const fetchEvent = withErrorHandler(async (id) => {
  return await EventService.get(id);
})//generate delete event action
export const deleteEvent = withErrorHandler(async (event) => {
  return await EventService.remove(event.id);
})

export const registerEvent = withErrorHandler(async (event) => {
  return EventAttendanceService.create(event);
})
// updateRegisterEvent
export const updateRegisterEvent = withErrorHandler(async (registerId, registerInfo) => {
  return await EventAttendanceService.patch(registerId, {registerInfo});
})

export const fetchEventAttendance = withErrorHandler(async (params) => {
  const res = await EventAttendanceService.find(params);
  return res.data;
})
export const fetchEventsAttendance = withErrorHandler(async (params) => {
 return  await EventAttendanceService.find(params);

})

//find event attendance by profile id
export const fetchAttendedEventsByProfileId = withErrorHandler(async (profileId) => {
  return await fetchEventAttendance({
    query: {
      profileId: profileId
    }
  });
})
//fetch event attendance by event id and profile id
export const fetchEventAttendanceByEventId = withErrorHandler(async (eventId, profileId) => {
  return await fetchEventAttendance({
    query: {
      eventId: eventId,
      profileId: profileId,
    }
  });
})

export const fetchAllEventAttendanceByEventId = withErrorHandler(async (eventId) => {
  return await fetchEventAttendance({
    query: {
      eventId: eventId,
      $limit: 1000,
    }
  });
})

export const fetchAllEventAttendanceByEventIdWithProTable = withErrorHandler(({updatedParams:params, sort, filter}) => {
  removeNullValue(params)
  removeNullValue(filter)
  const $sort = _.transform(sort, function (result, value, key) {
    result[key] = sortMapping[value]
    return result
  }, {});
  const {current = 1, pageSize: $limit = 1000,  ...requestParams} = params
  // const handleRequest = _.transform(requestParams, function (result, value, key) {
  //   if (key === 'groupId' || key == "id" ) {
  //     result[key] = {'$eq': value}
  //   } else
  //     result[key] = {$like: `%${value}%`}
  //   return result
  // }, {});
  // const searchData = {query: {$sort, $limit, $skip: (current - 1) * $limit, groupId, ...request, ...filter,}}
  const searchData = {query: {$sort, $limit, $skip: (current - 1) * $limit, ...requestParams,  ...filter,}}
  return fetchEventsAttendance(searchData)

})



export const fetchCountRegister = withErrorHandler(async (id) => {
  return StatisticsEvents.get(id)
})


export const createReviewInteract = withErrorHandler(async (interact) => {
  return ReviewInteract.create(interact);
})

