import {get, post, putRequest} from "../api/common";
import {fetchAllProfiles, sortMapping} from "./profile";
import {withErrorHandler} from "./common";
import _ from "lodash";


export function removeNullValue(obj) {
  Object.keys(obj).forEach(k => {
    !obj[k] && (delete obj[k]);
  });
}

export const fetchAllStudents = withErrorHandler(({params, sort, filter}) => {
  removeNullValue(params)
  removeNullValue(filter)

  const $sort = _.transform(sort, function (result, value, key) {
    result[key] = sortMapping[value]
    return result
  }, {});
  const {current = 1, pageSize: $limit = 1000, ...requestParams} = params
  const handleRequest = _.transform(requestParams, function (result, value, key) {
    if (key === 'groupId' || key == "id" ) {
      result[key] = {'$eq': value}
    } else
      result[key] = {$like: `%${value}%`}
    return result
  }, {});
const {groupId:groupValue, ...request} = handleRequest
  const groupId = {$ne: 9999, ...groupValue}
  const searchData = {query: {$sort, $limit, $skip: (current - 1) * $limit, groupId, ...request, ...filter,}}
  return fetchAllProfiles(searchData)
})

