import React from "react";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import {useAppAbility} from "../util/Can";
import {Button, Result} from "antd";
import {useSelector} from "react-redux";


const Unauthorized = () => {
  const {authUser, initURL} = useSelector(({auth}) => auth);
  const history = useHistory();
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={<Button type="primary" onClick={() => {
        history.push(`/profile/${authUser.profileId}`)
      }}>Back Home</Button>}
    />
  );
};

function usePathInfo() {
  const {pathname} = useLocation();
  const pathParts = pathname.split("/").filter(s => !!s.length);
  const type = pathParts[0]
  const id = pathParts[1]
  return {type, id}
}

const ProtectedRoute = ({component: Component, action, subject, ...rest}) => {
  const ability = useAppAbility();
  const pathInfo = usePathInfo()
  const viewDetail = !!pathInfo.id;
  let canAccess = ability.can('access', viewDetail ? pathInfo : pathInfo.type);
  if (viewDetail && !canAccess && pathInfo.type == "profile") {
    canAccess = ability.can('access', `students`);
  }
  return (
    <Route {...rest} render={(props) => (
      canAccess ? <Component {...props} /> : <Unauthorized/>
    )}/>
  );
};

const App = ({match}) => {

  return (
    <div className="gx-main-content-wrapper gx-p-3 px-moblie-0 ">
      <Switch>
        <ProtectedRoute path={`${match.url}profile/:id`}
                        component={asyncComponent(() => import('./profile/view'))}/>
        <Route path={`${match.url}profiles`} component={asyncComponent(() => import('./profile'))}/>
        <Route path={`${match.url}student/:id`} component={asyncComponent(() => import('./student/View'))}/>
        <ProtectedRoute path={`${match.url}students`} component={asyncComponent(() => import('./student/'))}/>
        <ProtectedRoute path={`${match.url}group/:id`} component={asyncComponent(() => import('./group/View'))}/>
        <ProtectedRoute path={`${match.url}groups`} component={asyncComponent(() => import('./group/'))}/>
        <ProtectedRoute path={`${match.url}events`} component={asyncComponent(() => import('./event/'))}/>
        <ProtectedRoute path={`${match.url}applications`} component={asyncComponent(() => import('./profileApplication'))}/>
        <ProtectedRoute path={`${match.url}admin`} component={asyncComponent(() => import('./admin'))}/>
        <Route path={`${match.url}home`} component={asyncComponent(() => import('./home/'))}/>
        <Route path={`${match.url}event/:eventId`}
                        component={asyncComponent(() => import('./event/View'))} />
      </Switch>
    </div>
  );
};

export default App;
